import React, {useState, useEffect} from "react"
import axios from "axios"
import { useForm } from "react-hook-form";
import Layout from "../components/layout"
import Logout from "../components/Form/Logout"
import SEO from "../components/seo"

async function login(formData) {
    const endpoint = process.env.GATSBY_ISTNOTYOU_LOGIN;
    // const endpoint = 'http://localhost:3000/token'
    const result = await axios.post(endpoint, formData, {
        headers: {
            'Content-Type': 'multipart/form-data',
            'itsus-token': process.env.GATSBY_ISTNOTYOU_TOKEN
        }
    })
    const res = await result.data;
    return res;
}

async function updateCountries() {
    const endpoint = process.env.GATSBY_ALL_COUNTRIES_TRAVEL_DATA;
    // const endpoint = 'http://localhost:3000/token'
    const result = await axios.get(endpoint, {
        headers: {
            'Content-Type': 'multipart/form-data',
            'hypermix-token': process.env.GATSBY_ISTNOTYOU_TOKEN
        }
    })
    const res = await result.data;
    return res;
}

// async function forceRunBuild() {
//     const endpoint = process.env.GATSBY_RUN_BUILD;
//     // const endpoint = 'http://localhost:3000/token'
//     const result = await axios.get(endpoint, {
//         headers: {
//             'Content-Type': 'multipart/form-data',
//             'itsus-token': process.env.GATSBY_ISTNOTYOU_TOKEN
//         }
//     })
//     const res = await result.data;
//     return res;
// }

const LoggedOut = ({authenticated, ...props}) => {
    console.log("IS AUTHENTICATED", authenticated)

    const handleUpdateCountries = () => {
        updateCountries()
        .then(response => {
            console.log("THE RESPONSE", response)
        })
        .catch(function (error) {
            console.log("UPDATE COUNTRIES ERROR", error)
            console.log("UPDATE COUNTRIES ERROR", error.response)
        })
    }

    return (
        <div className="uk-container uk-container-small">
            <div className="uk-margin-large">
                <Logout />
            </div>
            <div className="uk-grid uk-grid-large">
                <div>
                    <button
                        onClick={handleUpdateCountries}
                        className="uk-button uk-button-large uk-button-primary">Update countries</button>
                </div>
                <div>
                    <button
                        onClick={handleUpdateCountries}
                        className="uk-button uk-button-large uk-button-primary">Update countries</button>
                </div>
            </div>
        </div>
    )
}

const Login = ({location, ...props}) => {
    const { register, handleSubmit, errors } = useForm({mode: "onBlur",});
    const [loginError, setLoginError] = useState({})
    const [authenticated, setAuthenticated] = useState(false)

    useEffect(
        () => {
            const token = localStorage.auth
            token && token.length ? setAuthenticated(true) : setAuthenticated(false)
        }, []
    )

    const onSubmit = data => {
        // setIsLoading(true)
        let formData = new FormData();
        formData.append("username", data.username);
        formData.append("password", data.password);

        login(formData)
        .then(response => {
            const token = response.access_token
            localStorage.setItem('auth', token)
            window.location.replace(`/`);
        })
        .catch(function (error) {
            setLoginError(error.response)
            console.log("FIRST CATCH ERROR", error)
            console.log("FIRST CATCH ERROR", error.response)
        })

    };

    return (
        <Layout location={location}>
            <SEO title={"Sign in"} pathname={location.pathname} />
            <section className="uk-section uk-flex uk-flex-column uk-flex-center uk-flex-middle uk-section-default uk-height-viewport uk-padding-margin-vertical" data-uk-height-viewport>
                <div className="uk-position-bottom-center uk-position-small uk-visible@m uk-position-z-index">
                    <span className="uk-text-small uk-text-muted">©2020 Michael Lisboa <a href="https://www.hypermix.com">Hypermix.com</a></span>
                </div>
                {authenticated ?
                    <LoggedOut authenticated={authenticated} />
                    :
                    <>
                    {loginError.length ? <h1 className="uk-width-1-1 uk-text-center">Nope.</h1> : null }
                    <div className="uk-width-medium uk-padding-small">
                        <form className="uk-form" onSubmit={handleSubmit(onSubmit)}>
                            <fieldset className="uk-fieldset">
                                <div className="uk-margin-small">
                                    <div className="uk-inline uk-width-1-1">
                                        <span className="uk-form-icon" data-uk-icon="icon: user" />
                                        <input
                                            ref={register}
                                            name="username"
                                            className="uk-input uk-border-rounded"
                                            required
                                            placeholder="Username"
                                            defaultValue={""}
                                            autoComplete="off"
                                            autoCapitalize="off"
                                            autoCorrect="off"
                                            type="text" />
                                    </div>
                                </div>
                                <div className="uk-margin-small">
                                    <div className="uk-inline uk-width-1-1">
                                        <span className="uk-form-icon" data-uk-icon="icon: lock" />
                                        <input
                                            ref={register}
                                            name="password"
                                            className="uk-input uk-border-rounded"
                                            required
                                            placeholder="Password"
                                            defaultValue={""}
                                            autoComplete="new-password"
                                            autoCapitalize="off"
                                            autoCorrect="off"
                                            type="password" />
                                    </div>
                                </div>
                                <div className="uk-margin-bottom">
                                    <button type="submit" disabled={errors.length > 0}
                                        className="uk-button uk-button-secondary uk-border-rounded uk-width-1-1">LOG IN</button>
                                </div>
                            </fieldset>
                        </form>
                    </div>
                    </>
                }
            </section>
        </Layout>
    )
}

export default Login
