import React, { useState, useEffect, useRef } from "react"

const Logout = ({...props}) => {

    const handleClick = e => {
        localStorage.removeItem("auth")
        window.location.replace(`/`);
    }

    return (
        <button type="button"
            onClick={handleClick}
            className="uk-button uk-button-text cta-button uk-width-1-1">LOG OUT</button>
    )
}

export default Logout;
